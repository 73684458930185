import React from 'react'
import Marquee from 'react-fast-marquee'

// Import all images directly
import img1 from '../sponsors/1-ActonaGroup.png'
import img2 from '../sponsors/2-JJD.png'
import img3 from '../sponsors/3-BMSilo.png'
import img4 from '../sponsors/4-TCMGroup.png'
import img5 from '../sponsors/5-BorbjergSparekasse.png'
import img6 from '../sponsors/6-OJMCNCMachining.png'
import img7 from '../sponsors/7-CarstenSlyk.png'
import img8 from '../sponsors/8-BjarneNielsenHolstebro.png'
import img9 from '../sponsors/9-Aubo.png'
import img10 from '../sponsors/10-SundtHus.png'
import img11 from '../sponsors/11-SparTvis.png'
import img12 from '../sponsors/12-VestjyskBank.png'
import img13 from '../sponsors/13-JyskeBank.png'
import img14 from '../sponsors/14-CarstenJensen.png'
import img15 from '../sponsors/15-Landbobanken.png'
import img16 from '../sponsors/16-SparekassenBalling.png'
import img17 from '../sponsors/17-HolstebroSandOgGrus.png'
import img18 from '../sponsors/18-LundSolfilm.png'
import img19 from '../sponsors/19-BMS.png'
import img20 from '../sponsors/20-MidtjyskAlbyg.png'
import img21 from '../sponsors/21-TangBiler.png'
import img22 from '../sponsors/22-TvisVognmandsforretning.png'
import img23 from '../sponsors/23-ThordalVentilation.png'

const Sponsor = () =>
{
	// Use the imported images directly
	const images = [
		{ src: img1, name: '1-ActonaGroup' },
		{ src: img2, name: '2-JJD' },
		{ src: img3, name: '3-BMSilo' },
		{ src: img4, name: '4-TCMGroup' },
		{ src: img5, name: '5-BorbjergSparekasse' },
		{ src: img6, name: '6-OJMCNCMachining' },
		{ src: img7, name: '7-CarstenSlyk' },
		{ src: img8, name: '8-BjarneNielsenHolstebro' },
		{ src: img9, name: '9-Aubo' },
		{ src: img10, name: '10-SundtHus' },
		{ src: img11, name: '11-SparTvis' },
		{ src: img12, name: '12-VestjyskBank' },
		{ src: img13, name: '13-JyskeBank' },
		{ src: img14, name: '14-CarstenJensen' },
		{ src: img15, name: '15-Landbobanken' },
		{ src: img16, name: '16-SparekassenBalling' },
		{ src: img17, name: '17-HolstebroSandOgGrus' },
		{ src: img18, name: '18-LundSolfilm' },
		{ src: img19, name: '19-BMS' },
		{ src: img20, name: '20-MidtjyskAlbyg' },
		{ src: img21, name: '21-TangBiler' },
		{ src: img22, name: '22-TvisVognmandsforretning' },
		{ src: img23, name: '23-ThordalVentilation' },
	]

	if (images.length === 0) return null

	return (
		<footer className="content-footer footer bg-white w-100 py-3" style={{position: 'fixed', bottom: 0}}>
			<Marquee
				speed={40}
				gradient={false}
				pauseOnHover={false}
			>
				{images.map((image, index) => (
					<img
						key={index}
						src={image.src}
						alt={`Sponsor ${image.name}`}
						className="mx-4"
						style={{ height: '50px', objectFit: 'contain' }}
					/>
				))}
			</Marquee>
		</footer>
	)
}

export default Sponsor
