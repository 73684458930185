import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/esm/Card'
import {CardBody} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faShirt, faSquare } from '@fortawesome/free-solid-svg-icons'


class ActivityEntry extends React.Component	
{
	constructor(props)
	{
		super(props)
		this.state = { }
	}
	translateWeekday(weekday)
	{
		const map = {
			monday: 'Mandag',
			tuesday: 'Tirsdag',
			wednesday: 'Onsdag',
			thursday: 'Torsdag',
			friday: 'Fredag',
			saturday: 'Lørdag',
			sunday: 'Søndag',
		}

		return map[weekday]
	}
	translatePlace(type)
	{
		const map = {
			'sundthus': 'SundtHus',
			'jysk':	'Jysk',
			'jjd':	'JJD',
			'ojm':	'O. Jacobsen Maskinfabrik',
			'bms':	'BM Silo',
			'vb':	'Vestjysk Bank',
			'dom': 'Dameomklædning i kælderen',
			'hom': 'Herreomklædning i kælderen',
		}
		return map[type]
	}

	translateColor(color) 
	{
		const colorMap = {
			Hvid: '#FCFCFC',
			Sort: '#2f2d2d',
			Grå: '#808080',
			Rød: '#FF2222',
			Lyserød: '#FFC0CB',
			Mørkerød: '#8B0000',
			Blå: '#0000FF',
			Lyseblå: '#ADD8E6',
			Mørkeblå: '#0000B9',
			Grøn: '#008000',
			Lysegrøn: '#90EE90',
			Mørkegrøn: '#006400',
			Gul: '#FFFF00',
			Lysegul: '#FFFFE0',
			Mørkegul: '#FFD700',
			Lilla: '#800080',
			Lyselilla: '#D8BFD8',
			Mørkelilla: '#4B0082',
			Orange: '#FFA500',
			Lyseorange: '#FFDAB9',
			Mørkeorange: '#FF8C00',
		}

		return colorMap[color] ?? null
	}
	render()
	{
		let leftShirt = ''
		let rightShirt = ''
		const entry = this.props.json
		const date = new Date
		const from = entry.timeFrom.replace(':', '')
		const too = entry.timeToo.replace(':', '')
		const current_time = date.getHours()+''+date.getMinutes()
		if (entry.type === '3')
		{
			entry.eventName = entry.eventName + ' i ' + this.translatePlace(entry.eventPlace)
		}

		if (entry.leftShirt !== undefined)
		{
			let shirtColor = this.translateColor(entry.leftShirt.ShirtColor)
			let shortsColor = this.translateColor(entry.leftShirt.ShortsColor) ?? shirtColor

			leftShirt = (<span className="fa-stack" style={{verticalAlign: 'top'}}>
				<FontAwesomeIcon
					className='fa-stack-2x'
					icon={faSquare}
					style={{
						color: 'var(--bs-body-bg)',
					}}
				/>
				<FontAwesomeIcon
					className='fa-stack-1x'
					icon={faShirt}
					style={{
						color: shirtColor,
						clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)', /// top half
					}}
				/>
				<FontAwesomeIcon
					className='fa-stack-1x'
					icon={faShirt}
					style={{
						color: shortsColor,
						clipPath: 'polygon(0 0, 100% 0, 100% 0%, 0% 100%)', // bottom half
					}}
				/>
			</span>)
		}
		if (entry.rightShirt !== undefined)
		{
			let shirtColor = this.translateColor(entry.rightShirt.ShirtColor)
			let shortsColor = this.translateColor(entry.rightShirt.ShortsColor) ?? shirtColor
			rightShirt = (<span className="fa-stack" style={{verticalAlign: 'top'}}>
				<FontAwesomeIcon
					className='fa-stack-2x'
					icon={faSquare}
					style={{
						color: 'var(--bs-body-bg)',
					}}
				/>
				<FontAwesomeIcon
					className='fa-stack-1x'
					icon={faShirt}
					style={{
						color: shirtColor,
						clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)', /// top half
					}}
				/>
				<FontAwesomeIcon
					className='fa-stack-1x'
					icon={faShirt}
					style={{
						color: shortsColor,
						clipPath: 'polygon(0 0, 100% 0, 100% 0%, 0% 100%)', // bottom half
					}}
				/>
			</span>)
		}

		return (
			<Card className={(from <= current_time && too >= current_time ?  'active' : 'border-secondary') + ' entry'}>
				<CardBody>
					<div className="name-part">
						<h3 className="m-0 p-0 mb-3"><span className="time-part" style={{color: (from <= current_time && too >= current_time ? '#46c389' : '#e76f51')}}>{entry.timeFrom} </span> {entry.subEventName}</h3>
						<span style={{fontSize: '35px'}}>{leftShirt}</span>{entry.eventName}<span style={{fontSize: '35px'}}>{rightShirt}</span>
					 </div>

				</CardBody>
			</Card>
		)
	}
}

ActivityEntry.propTypes = {
	json: PropTypes.object,
	first: PropTypes.bool,
}

export default ActivityEntry
